export function getQueryParam(queryString: string) {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(queryString);
}
/**
 * Build CTA url based on origin, saleid, vehicle id and market from querystrings.
 * @returns
 */
export function buildCTAUrl() {
  const onlineSaleId = getQueryParam("onlineSaleId");
  const vehicleId = getQueryParam("vehicleId");
  const market = getQueryParam("market");
  return `${window.location.origin}/us/web/en/myaudi/reservation.html?onlineSaleId=${onlineSaleId}&vehicleId=${vehicleId}&market=${market}`;
}

/**
 * Update SessionStorage with error messages.
 * @param error
 */
export function saveOfaErrors(newError: any) {
  // Check if any previous messages.
  const errors = sessionStorage.getItem("OFA_ERROR_MESSAGES");
  if (errors) {
    const currentErrors = JSON.parse(errors);
    // Check if array
    if (Array.isArray(currentErrors)) {
      currentErrors.push(newError);
      sessionStorage.setItem("OFA_ERROR_MESSAGES", JSON.stringify(currentErrors));
    }
  } else {
    sessionStorage.setItem("OFA_ERROR_MESSAGES", JSON.stringify([newError]));
  }
}
