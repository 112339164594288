/* eslint-disable no-else-return */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import "./index.css";
import type { FeatureAppDefinition, FeatureServices } from "@feature-hub/core";
import type { ReactFeatureApp } from "@feature-hub/react";
import type { AuthServiceProviderV2, IAuthServiceV2 } from "@oneaudi/audi-auth-service";
import validate from "../../validation/vendor-data-validation";
import { vendorDataMapper } from "../../validation/vendor-data-mapper/data_mapper";
import Ofa, { OfaData, Origin } from "./ofa";
import FeatureAppOfaService, { FeatureAppOfaService as OfaService } from "../featureAppService/OfaServices";
import { logger } from "../../../logger";
import { getQueryParam, buildCTAUrl, saveOfaErrors } from "./common";
import ErrorScreen from "../GlobalErrorComponent";
import { DEFAULT_TEXT_TITLE, DEFAULT_TEXT_MSG, DEFAULT_TEXT_MORE_INFO, E_PE102_HEADER_TEXT, E_PE102_BODY_TEXT } from "../Utils/Messages";
import { errorCodes } from "../../validation/app-data-validation/internal_errorCodes";
import Loader from "../Loader";

interface FeatureServiceDependencies extends FeatureServices {
  "ofa:feature-app-ofa-service": OfaService;
  "vw:authService": AuthServiceProviderV2;
}

type FeatureAppComponentProps = {
  ofaService: OfaService;
  authService: IAuthServiceV2;
  parms: object;
};

const DEFAULT_ORIGIN: Origin = {
  isAudiUsa: false,
  isAudiCa: false,
  isFeatureApp: true,
  domain: window.location.origin,
};

/**
 * Get origin of feature app.
 * @param {string} contractCountry
 * @param {string} origin
 */
function getOrigin(contractCountry: string, origin: string) {
  const origins = { ...DEFAULT_ORIGIN };

  if (contractCountry.toLowerCase() === "us" || origin.includes("audiusa")) {
    origins.isAudiUsa = true;
  }

  if (contractCountry.toLowerCase() === "ca" || origin.includes("audi.ca")) {
    origins.isAudiCa = true;
  }

  return origins;
}

const fetchData = async ({
  functionName,
  setIsLoading,
  setError,
  authService,
  onlineSaleId,
  setOnlineSaleId,
  ofaService,
  setCurrentZoidError,
  setProfileData,
  setOrigin,
}) => {
  setIsLoading(true);
  setError(null);
  let onlineSaleIdFromParam = onlineSaleId;
  try {
    const isAuthenticated = await authService.isAuthenticated();
    onlineSaleIdFromParam = getQueryParam("onlineSaleId") || "3E35W535";
    setOnlineSaleId(onlineSaleIdFromParam);
    console.debug("OFA Feature App isAuthenticated", isAuthenticated);
    logger.activity(functionName, {
      event: { isAuthenticated, onlineSaleId: onlineSaleIdFromParam },
      message: "OFA Feature App isAuthenticated",
    });

    if (isAuthenticated) {
      const idKitAccessToken = await authService.getAccessToken();
      console.debug("OFA Feature App idKitAccessToken", idKitAccessToken);
      const profileData = await ofaService.getPrefillData(idKitAccessToken, onlineSaleIdFromParam);
      const forceError = getQueryParam("forceError") || false;
      const mappedData = forceError ? profileData : vendorDataMapper(profileData?.data);

      let validationRes: any = { isDataValid: false };
      try {
        validationRes = await validate(mappedData, { isAudiUsa: true });
      } catch (validationError) {
        logger.error(`${functionName}.validationError`, {
          event: { error: validationError, onlineSaleId: onlineSaleIdFromParam },
          message: "Validation error occured",
        });
        throw validationError;
      }

      if (validationRes && !validationRes.isDataValid) {
        logger.error(`${functionName}.validationError`, {
          event: { error: validationRes?.errorMessage, onlineSaleId: onlineSaleIdFromParam },
          message: "Validation error occured",
        });
        saveOfaErrors(validationRes?.errorMessage);
        setError("Validation");

        if (validationRes?.errorDetail?.[0] === errorCodes?.financedAmount) {
          // The error related to Insufficient Financed amount is set.
          setCurrentZoidError({
            title: E_PE102_HEADER_TEXT,
            msg: E_PE102_BODY_TEXT,
          });
        } else {
          // Set the default error
          setCurrentZoidError({
            title: DEFAULT_TEXT_TITLE,
            msg: DEFAULT_TEXT_MSG,
            moreInfo: `${DEFAULT_TEXT_MORE_INFO} ${validationRes.errorDetail}`,
          });
        }
      } else {
        console.log(`Data has been passed and validated ${mappedData}`);
        setProfileData(profileData?.data);
        const originValue = getOrigin(profileData?.contractCountry, window.location.origin);
        setOrigin(originValue);
      }
    }
  } catch (error1) {
    logger.error(functionName, {
      event: { error: error1, onlineSaleId: onlineSaleIdFromParam },
      message: "OFA Feature App has error",
    });
    console.error(`OFA Feature App:: ${error1}`);
    setCurrentZoidError({
      title: DEFAULT_TEXT_TITLE,
      msg: DEFAULT_TEXT_MSG,
      moreInfo: `${DEFAULT_TEXT_MORE_INFO} Internal-Error`,
    });
  } finally {
    setIsLoading(false);
  }
};

function renderLoading({
  isLoading,
  error,
  currentZoidError,
  profileDataState,
  onClickModalError,
  parms,
  ofaService,
  authService,
  onlineSaleId,
  origin,
}) {
  if (isLoading) {
    console.warn(`OFA Feature App:: loading`);
    return <Loader />;
  }

  if (error) {
    console.error(`OFA Feature App:: ${error}`);
    return (
      <ErrorScreen
        title={currentZoidError?.title}
        message={currentZoidError?.msg}
        onClose={onClickModalError}
        buttonText="Return to my reservation"
        icon="caution"
      />
    );
  }

  if (!isLoading && error == null && profileDataState != null) {
    return (
      <Ofa
        parms={parms}
        profileData={profileDataState}
        ofaService={ofaService}
        authService={authService}
        onlineSaleId={onlineSaleId}
        origin={origin}
      ></Ofa>
    );
  }
  if (!isLoading && error == null && profileDataState == null) {
    console.error(`OFA Feature App:: Auth Error`);
    return (
      <ErrorScreen
        title={currentZoidError?.title}
        message={currentZoidError?.msg}
        onClose={onClickModalError}
        buttonText="Return to my reservation"
        icon="caution"
      />
    );
  }
  return <div />;
}

export function FeatureAppComponent({ ofaService, authService, parms }: FeatureAppComponentProps) {
  const [profileDataState, setProfileData] = useState<null | OfaData>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<null | string>(null);
  const [onlineSaleId, setOnlineSaleId] = useState("");
  const [origin, setOrigin] = useState<Origin>(DEFAULT_ORIGIN);
  const [currentZoidError, setCurrentZoidError] = useState({
    title: DEFAULT_TEXT_TITLE,
    msg: DEFAULT_TEXT_MSG,
    moreInfo: `${DEFAULT_TEXT_MORE_INFO} LoginError`,
  });

  const onClickModalError = () => {
    const url = buildCTAUrl();
    window?.location?.replace(url);
  };

  useEffect(() => {
    console.debug("OFA Feature App MOUNTED");
    const functionName = "FeatureAppComponent";
    logger.activity(functionName, {
      event: parms,
      message: "OFA Feature App MOUNTED",
    });

    fetchData({
      functionName,
      setIsLoading,
      setError,
      authService,
      onlineSaleId,
      setOnlineSaleId,
      ofaService,
      setCurrentZoidError,
      setProfileData,
      setOrigin,
    });
  }, []);

  return renderLoading({
    isLoading,
    error,
    currentZoidError,
    profileDataState,
    onClickModalError,
    parms,
    ofaService,
    authService,
    onlineSaleId,
    origin,
  });
}

const featureAppComponentDefinition: FeatureAppDefinition<ReactFeatureApp, FeatureServiceDependencies> = {
  dependencies: {
    externals: {
      react: "^17.0.2",
      "@feature-hub/react": "^2.9.0",
    },
    featureServices: {
      "ofa:feature-app-ofa-service": "^1.0.0",
      "vw:authService": "^2.2.0",
    },
  },
  ownFeatureServiceDefinitions: [FeatureAppOfaService],

  create(env) {
    const featureAppOfaService = env.featureServices["ofa:feature-app-ofa-service"];

    const provider = env.featureServices["vw:authService"];
    const audiAuthService = provider.register("myaudi");

    console.debug("OFA Feature App ENTERED CREATE");
    console.debug("OFA Feature App audiAuthService", audiAuthService);

    return {
      render() {
        return <FeatureAppComponent ofaService={featureAppOfaService} authService={audiAuthService} parms={env} />;
      },
    };
  },
};

export default featureAppComponentDefinition;
