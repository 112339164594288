import { uniqueUserId } from './uniqueIdContext';

const urlParams = new URLSearchParams(window.location.search);
 
class LoggerHelper {
    private event: any = null;
    private queryStringParameters: any = null;

    public setEvent(event: any) {
        this.event = event;
        this.queryStringParameters = event.queryStringParameters || {}
    }

    public creatLogPayLoad(level: string,
        methodName: string,
        error: Error | null | undefined,
        message?: any) {

        const logPayload: any = {
            appId: uniqueUserId,
            applicationName: "OFA.AudiUsaFeatureApp",
            buildNumber: "1.0.0",
            type: "FeatureApp",
            origin: window.location.origin,
            function: methodName,
            levelName: level,
            brand:"audi",
            country:"us",
            environment: process.env.REACT_APP_ENV,
            ...this.queryStringParameters,
            correlationId: urlParams.get("onlineSaleId") || "",
        }

        if (message || message !== "") {
            logPayload.message = message;
        }

        if (error) {
            logPayload.context =  { errorStack: null, error: null };
            logPayload.context.errorStack = error.stack ? error.stack : null;
            logPayload.context.error = error;
        }
        return logPayload;
    }
}

export const loggerHelper = new LoggerHelper();