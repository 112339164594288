import { ILogger } from "./ILogger";
import splunkLogger from "./splunkLogger"

import { loggerHelper } from "./LoggerHelper"

const shallInclude = (level:string) => {
    if(!process.env.EXCLUDE_LOGGING_LEVEL){
        return true;
    }
    if(process.env.EXCLUDE_LOGGING_LEVEL.includes(level)){
        return false
    }

    return true;
}

export class SplunkLoggerWrapper implements ILogger {

    // eslint-disable-next-line class-methods-use-this
    public debug(methodName: string, message?: any): void {
        const log = loggerHelper.creatLogPayLoad("debug", methodName, null, message)
        splunkLogger.debug(log)
    }

    // eslint-disable-next-line class-methods-use-this
    public info(methodName: string, message: any): void {
        if (shallInclude('info')){
            const log = loggerHelper.creatLogPayLoad("info", methodName, null, message)
            splunkLogger.debug(log)
        }
    }
    // eslint-disable-next-line class-methods-use-this
    public enter(methodName: string, message?: any): void {
        if (shallInclude('info')){
            const log = loggerHelper.creatLogPayLoad("info", methodName, null, message)
            splunkLogger.info(log)
        }
    }

    // eslint-disable-next-line class-methods-use-this
    public exit(methodName: string, message?: any): void {
      this.enter(methodName, message);
    }

    // eslint-disable-next-line class-methods-use-this
    public error(methodName: string, error: Error, message?: any): void {

        const log = loggerHelper.creatLogPayLoad("error", methodName, error, message)
        splunkLogger.error(log)

    }

    // eslint-disable-next-line class-methods-use-this
    public activity(methodName: string, message?: any, error?: Error): void {
        const log = loggerHelper.creatLogPayLoad("trace", methodName, error, message)
        splunkLogger.info(log)
    }
}
