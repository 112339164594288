enum ZoidEventEnum {
  RENDER = "zoid-render",
  RENDERED = "zoid-rendered",
  DISPLAY = "zoid-display",
  ERROR = "zoid-error",
  CLOSE = "zoid-close",
  DESTROY = "zoid-destroy",
  PROPS = "zoid-props",
  RESIZE = "zoid-resize",
  FOCUS = "zoid-focus",
}

type ZoidDimensions = {
  width: string | number;
  height: string | number;
};

type ZoidEventDimensions = {
  width?: number;
  height?: number;
};

type ZoidEvent =
  | {
      type: ZoidEventEnum.RENDERED;
      func: () => void;
    }
  | {
      type: ZoidEventEnum.RESIZE;
      func: (arg0: ZoidEventDimensions) => void;
    };

type ZoidRef = {
  uid: string;
  frame: HTMLIFrameElement;
  prerenderFrame: HTMLElement;
  doc: Document;
  props: {
    cspNonce: "string";
  };
  event: {
    on: (arg0: ZoidEvent["type"], arg1: ZoidEvent["func"]) => void;
  };
  dimensions: ZoidDimensions;
};

// Taken from the zoid code
export function defaultContainerTemplate(_ref: ZoidRef): HTMLElement {
  const { uid, frame, prerenderFrame, doc, props, event, dimensions } = _ref;
  const { width, height } = dimensions;
  const div = doc.createElement("div");
  if (frame && prerenderFrame) {
    div.setAttribute("id", uid);
    div.style.minHeight = "70vh";
    frame.style.minHeight = "70vh";
    const style = doc.createElement("style");
    if (props.cspNonce) {
      style.setAttribute("nonce", props.cspNonce);
    }
    style.appendChild(
      doc.createTextNode(
        `\n            #${uid} {\n                display: inline-block;\n                position: relative;\n                width: ${width};\n                height: ${height};\n            }\n\n            #${uid} > iframe {\n                display: inline-block;\n                position: absolute;\n                width: 100%;\n                height: 100%;\n                top: 0;\n                left: 0;\n                transition: opacity .2s ease-in-out;\n            }\n\n            #${uid} > iframe.zoid-invisible {\n                opacity: 0;\n            }\n\n            #${uid} > iframe.zoid-visible {\n                opacity: 1;\n        }\n        `
      )
    );
    div.appendChild(frame);
    div.appendChild(prerenderFrame);
    div.appendChild(style);
    prerenderFrame.classList.add("zoid-visible");
    frame.classList.add("zoid-invisible");
    event.on(ZoidEventEnum.RENDERED, () => {
      prerenderFrame.classList.remove("zoid-visible");
      prerenderFrame.classList.add("zoid-invisible");
      frame.classList.remove("zoid-invisible");
      frame.classList.add("zoid-visible");
      // eslint-disable-next-line func-names
      setTimeout(function () {
        destroyElement(prerenderFrame);
      }, 1);
    });
    event.on(ZoidEventEnum.RESIZE, (dimensionsEvent: ZoidEventDimensions) => {
      const newWidth = dimensionsEvent.width;
      const newHeight = dimensionsEvent.height;
      const increaseHeight = 30;

      if (typeof newWidth === "number") {
        div.style.width = toCSS(newWidth);
      }
      if (typeof newHeight === "number") {
        // Prevent infinite height increase
        if (newHeight !== newHeight + increaseHeight) {
          div.style.height = toCSS(newHeight);
        } else {
          // Because of windows showing scrollbar we need to add some extra pixels
          div.style.height = toCSS(newHeight + increaseHeight);
        }
      }
    });
  }
  return div;
}

function isPerc(str: string) {
  return /^[0-9]+%$/.test(str);
}
function toNum(val: string | number) {
  if (typeof val === "number") {
    return val;
  }
  const match = val.match(/^([0-9]+)(px|%)$/);
  if (!match) {
    throw new Error(`Could not match css value from ${val}`);
  }
  return parseInt(match[1], 10);
}
function toPx(val: string | number) {
  return `${toNum(val)}px`;
}
function pxFromString(val: string) {
  return isPerc(val) ? val : toPx(val);
}
function toCSS(val: string | number) {
  // eslint-disable-next-line no-nested-ternary
  return typeof val === "number" ? toPx(val) : pxFromString(val);
}

const destroyElement = (element: HTMLElement) => element && element.parentNode && element.parentNode.removeChild(element);
