import React from "react";
import { createFeatureHub } from "@feature-hub/core";
import { loadAmdModule } from "@feature-hub/module-loader-amd";
import { FeatureHubContextProvider, FeatureAppContainer } from "@feature-hub/react";
import featureAppComponentDefinition from "../FeatureAppComponent/FeatureAppComponent";
import FeatureAppOfaService from "../featureAppService/OfaServices";

export default function FeatureAppIntegrator() {
  const { featureAppManager } = createFeatureHub("myvw:integrator", {
    featureServiceDefinitions: [FeatureAppOfaService],
    moduleLoader: loadAmdModule,
  });


  return (
    <FeatureHubContextProvider value={{ featureAppManager }}>
      <FeatureAppContainer featureAppId={`myvw:featureApp=' ' feature`} featureAppDefinition={featureAppComponentDefinition} />
    </FeatureHubContextProvider>
  );
}
