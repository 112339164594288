/* eslint-disable react/function-component-definition */

import React from "react";

const Loader = () => {
  return (
    <div id="app-ofa-loader-container">
      <div className="c-spinner c-spinner--center c-spinner--small" aria-live="polite" aria-busy="true">
        <div className="c-spinner__loader">
          <span className="c-spinner__element"></span>
          <span className="c-spinner__element"></span>
          <span className="c-spinner__element"></span>
          <span className="c-spinner__element"></span>
          <span className="c-spinner__element"></span>
          <span className="c-spinner__element"></span>
          <span className="c-spinner__element"></span>
          <span className="c-spinner__element"></span>
        </div>
      </div>
    </div>
  );
};

export default Loader;
