/* eslint-disable func-names */
import bunyan  from "bunyan"; // eslint-disable-line import/no-unresolved
import splunkBunyan from "splunk-bunyan-logger"; // eslint-disable-line import/no-unresolved
//import {getAppVersion} from "./appVersion";
//import * as Models from '../../models';

const config = {
    token: process.env.SPLUNK_LOGGER_TOKEN || "4fcab5f3-4af4-42ff-8ed1-e88c900eb0e7",
    url: process.env.SPLUNK_LOGGER_URL || "https://hec.vwcredit.com",
    port: 443,
      maxRetries: 2
};

const splunkStream = splunkBunyan.createStream(config);

splunkStream.on("error", function(err, context) {
  console.log("Error", err, "Context", context);
});


/**
 * Override the default eventFormatter() function,
 * which takes a message and severity, returning
 * any type; string or object are recommended.
 *
 * The message parameter can be any type. It will
 * be whatever was passed to Logger.send().
 * Severity will always be a string.
 *
 * In this example, we're building up a string
 * of key=value pairs if message is an object,
 * otherwise the message value is as value for
 * the message key.
 *
 * This string is prefixed with the event
 * severity in square brackets.
 *
 * URL:https://dev.splunk.com/enterprise/docs/javascript/logging-javascript/loggingjavascripthowtos/howtoformathttpbunyan/
 */
splunkStream.setEventFormatter((message) => {

  const event = message;
  event.level = message.levelName;

  if (message.levelName) {
      delete message.levelName;
  }

  if (event.msg === "" || event.msg) {
      delete event.msg;
  }
  if (event.error || event.error===null) {
      delete event.error;
  }

  return event;
});


// Note: splunkStream must be set to an element in the streams array
const splunkLogger = bunyan.createLogger({
    name: "OFA.AudiUsaFeatureApp",
    streams: [
        splunkStream
    ]
});

export default splunkLogger;
