import React from "react";

type ErrorIconType = "semantic-warning" | "semantic-clock" | "caution";
interface ErrorScreenProps {
  icon: ErrorIconType;
  title: string;
  message: React.ReactNode;
  buttonText: string;
  onClose: () => void;
}

function GlobalErrorScreen({ icon, title, message, buttonText, onClose }: ErrorScreenProps) {
  return <div className="global-error-container">
    <div className="global-error-content-container">
      <i className={`c-icon c-icon--[${icon}] c-icon--medium`} role="img" aria-hidden="true" />
      <div className="global-error-header">{title}</div>
      <p className="global-error-text">{message}</p>
      <button className="global-error-button c-btn  c-btn--primary c-btn--full" type="button" onClick={() => onClose && onClose()}>
        <span className="c-btn__text">{buttonText}</span>
      </button>
    </div>
  </div>
}

export default GlobalErrorScreen;
