export const errorCodes: any = {
  brand: "OFA-G-1",
  contractCountry: "OFA-G-2",
  applicationType: "OFA-G-3",
  requestId: "OFA-G-5",
  id: "OFA-G-6",
  vehicleType: "OFA-G-7",
  preferredLanguage: "OFA-G-8",
  primaryFirstName: "OFA-PI-1",
  primaryLastName: "OFA-PI-2",
  primaryAddress1: "OFA-PI-3",
  primaryAddress2: "OFA-PI-4",
  primaryCity: "OFA-PI-5",
  primaryStateOrProvince: "OFA-PI-6",
  primaryPostalCode: "OFA-PI-7",
  primaryEmail: "OFA-PI-8",
  primaryPhone: "OFA-PI-9",
  dealerId: "OFA-D-1",
  dealerBrand: "OFA-G-4",
  dealerMarketingName: "OFA-D-2",
  dealerMarketingAddress1: "OFA-D-3",
  dealerMarketingAddress2: "OFA-D-4",
  dealerMarketingCity: "OFA-D-5",
  dealerMarketingState: "OFA-D-6",
  dealerMarketingZip: "OFA-D-7",
  dealerMarketingPhone: "OFA-D-8",
  vin: "OFA-V-1",
  model: "OFA-V-2",
  trim: "OFA-V-4",
  Year: "OFA-V-3",
  mileage: "OFA-V-4",
  term: "OFA-F-1",
  freightAndPDI: "OFA-F-2",
  financeType: "OFA-F-3",
  paymentFrequency: "OFA-F-4",
  purchasePrice: "OFA-F-5",
  financedAmount: "PE102",
  netAmountFinanced: "OFA-F-6",
  downPaymentAmount: "OFA-F-7",
  vehiclePrice: "OFA-F-8",
  paymentAmount: "OFA-F-9",
  provincialSalesTax: "OFA-T-1",
};

const InternalErrorList: string[] = [
  "OFA-D-1",
  "OFA-D-2",
  "OFA-D-3",
  "OFA-D-4",
  "OFA-D-5",
  "OFA-D-6",
  "OFA-D-7",
  "OFA-D-8",
  "OFA-V-1",
  "OFA-V-2",
  "OFA-V-3",
  "OFA-V-4",
  "OFA-F-1",
  "OFA-F-2",
  "OFA-F-3",
  "OFA-F-4",
  "OFA-F-5",
  "OFA-F-6",
  "OFA-F-8",
  "OFA-F-9",
  "OFA-T-1",
];

export const getErrorCodes: any = (errMessage: string) => {
  const errorCodesList: string[] = [];
  let showInternalError = false;
  errMessage.split(".").forEach((message: string) => {
    const error: string = message.substring(message.indexOf('"') + 1, message.lastIndexOf('"'));
    if (InternalErrorList.indexOf(errorCodes[`${error}`]) !== -1) showInternalError = true;
    if (errorCodesList.indexOf(errorCodes[`${error}`]) === -1 && errorCodes[`${error}`]) errorCodesList.push(errorCodes[`${error}`]);
  });
  return { errorCodesList, showInternalError };
};
