/* eslint-disable default-param-last */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable prefer-template */
/* eslint-disable no-undef */
/* eslint-disable class-methods-use-this */
/* eslint-disable prefer-const */
/* eslint-disable no-unused-vars */
import axios, { AxiosResponse } from "axios";
import { SUBMITTED } from "../Utils/AOSSubmitConst";
import { aossDataMap, getPhone, getPhoneType } from "./asooDataMap";
import { logger } from "../../../logger";
import { MappedData, OfaApplicant, OfaData } from "../FeatureAppComponent/ofa";

export class FeatureAppOfaService {
  async getPrefillData(jwt: string, onlineSaleId: string) {
    try {
      const profileData = await this.getUserData(jwt);
      const aossData = await this.getAossData(jwt, onlineSaleId);
      const mappedData = this.mapData(profileData?.data?.user!, aossData?.data?.creditAppPrefill!);
      const functionName = "FeatureAppOfaService.getPrefillData";

      logger.activity(functionName, {
        event: { mappedData, jwt },
        message: "aossData & profileData has been mapped",
      });

      return mappedData;
    } catch (error) {
      console.error(`OFA Feature App:: ${error}`);
      logger.error("FeatureAppOfaService.getPrefillData.error", {
        event: { error, jwt },
        message: "FeatureAppOfaService has error",
      });
      throw error;
    }
  }

  /**
   * Get brand, finance, vehicle, tradein and dealer data.
   * // tmp default onlineSaleId to mock-osi
   * @param {string} jwt
   * @param {string} onlineSaleId
   * @returns
   */
  async getAossData(jwt: string, onlineSaleId: string): Promise<AossDataResponse> {
    const functionName = "FeatureAppOfaService.getAossData";
    try {
      const aossData: AxiosResponse<AossDataResponse> = await axios({
        url: `${process.env.REACT_APP_OFA_VENDOR_DATA_API_URL}/audiusadata`,
        method: "post",
        headers: {
          token: jwt,
          onlineSaleId: onlineSaleId,
        },
      });

      logger.activity(functionName, {
        event: {
          data: aossData.data,
          url: `${process.env.REACT_APP_OFA_VENDOR_DATA_API_URL}/audiusadata`,
          token: jwt,
          onlineSaleId: onlineSaleId,
        },
        message: "assos gql api call",
      });

      return aossData.data;
    } catch (error: any) {
      logger.error(`${functionName}.error`, {
        event: { error, message: error?.message },
        message: "getAossData has error",
      });
      throw error;
    }
  }

  /**
   * Get user information.
   * @param {string} jwt
   * @returns
   */
  async getUserData(jwt: string): Promise<UserDataResponse> {
    const functionName = "FeatureAppOfaService.getUserData";
    try {
      const userData: AxiosResponse<UserDataResponse> = await axios({
        url: `${process.env.REACT_APP_OFA_VENDOR_DATA_API_URL}/audiusauser`,
        method: "post",
        headers: {
          token: jwt,
        },
      });

      logger.activity(functionName, {
        event: { data: userData.data, url: `${process.env.REACT_APP_OFA_VENDOR_DATA_API_URL}/audiusauser`, token: jwt },
        message: "getUserData gql api call",
      });

      return userData.data;
    } catch (error: any) {
      logger.error(`${functionName}.error`, {
        event: { error, message: error?.message },
        message: "getUserData has error",
      });
      throw error;
    }
  }

  mapLanguage(preferredLanguage: string | undefined) {
    if (preferredLanguage) {
      switch (preferredLanguage.toLocaleLowerCase()) {
        case "en":
          return "en-US";
        case "enus":
          return "en-US";
        default:
          return preferredLanguage;
      }
    }
    return "en-US";
  }

  cleanNullValue(audiDataField: string | undefined, defaultDataField: string) {
    if (audiDataField) {
      if (audiDataField.toLowerCase() === "null") return defaultDataField;
      return audiDataField;
    } return defaultDataField;
  }

  /**
   * Map information to be passed to Zoid component.
   * @param {UserDataResponse} userData
   * @param {AossDataResponse} aossData
   * @returns {MappedData}
   */
  mapData(userData: UserResult, aossData: CreditAppPrefillResult): MappedData {
    const mappedAossData = aossDataMap(aossData);

    const primaryAddress = userData?.addresses?.filter((address) => address?.primary === true);
    const primaryPhoneNumber = userData?.phoneNumbers?.filter((phone) => phone?.primary === true);
    const userAddress = primaryAddress?.[0] || userData?.addresses?.[0];
    const userPhone = primaryPhoneNumber?.[0] || userData?.phoneNumbers?.[0];

    const primaryApplicant: OfaApplicant = {
      firstName: this.cleanNullValue(userData?.firstName, ""),
      lastName: this.cleanNullValue(userData?.lastName, ""),
      dateOfBirth: "",
      identificationNumber: "",
      monthlyLivingExpenses: "",
      emailAddress: userData.primaryEmailAddress!,
      phoneNumber: getPhone(this.cleanNullValue(userPhone?.number.toString().trim(),"")),
      phoneType: getPhoneType(this.cleanNullValue(userPhone?.type?.toString().trim(),"")),

      // Tmp all true
      acknowledgeConsent: {
        consentPipeda: true,
        consentCasl: true,
      },
      residence: {
        address: {
          addressLine1: `${this.cleanNullValue(userAddress?.houseNumber?.trim(), "")} ${this.cleanNullValue(userAddress?.street?.trim(), "")}`.trim(),
          addressLine2: this.cleanNullValue(userAddress?.addressAddition, "NA"), // tmp default to NA since ofa validates this
          city: this.cleanNullValue(userAddress?.city, ""),
          zipOrPostalCode: this.cleanNullValue(userAddress?.zipCode, ""),
          StateOrProvince: this.cleanNullValue(userAddress?.state, ""),
          postalCode: null,
          country: this.cleanNullValue(userAddress?.country, ""),
        },
        timeAtAddress: {
          // Add default time at address
          years: "4",
          months: "9",
        },
        residenceType: "own", // add default value
      },
    };

    const profileData: OfaData = {
      ...mappedAossData,
      // Default to en if user has no preferredLanguages
      preferredLanguage: this.mapLanguage(userData?.preferredLanguages?.[0]?.toString()),
      primaryApplicant: { ...primaryApplicant },
    };

    return {
      data: profileData,
      contractCountry: aossData?.contractCountry ?? "",
    };
  }

  async aosSubmit(token: string, onlinesaleid: string, dmsNumber = "mock-credit-app-id") {
    try {
      const aosSubmitResponse = await axios({
        url: `${process.env.REACT_APP_OFA_VENDOR_DATA_API_URL}/audiusasubmit`,
        method: "post",
        headers: {
          token,
          onlinesaleid,
        },
        data: {
          dmsNumber,
          status: SUBMITTED,
          submissionDate: new Date().toISOString(),
        },
      });

      logger.activity("FeatureAppOfaService.aosSubmit", {
        event: { data: aosSubmitResponse.data, url: `${process.env.REACT_APP_OFA_VENDOR_DATA_API_URL}/audiusasubmit`, token, onlinesaleid },
        message: "aosSubmit gql api call",
      });

      return aosSubmitResponse.data;
    } catch (error: any) {
      logger.error("FeatureAppOfaService.aosSubmit.error", {
        event: { error, url: `${process.env.REACT_APP_OFA_VENDOR_DATA_API_URL}/audiusasubmit`, token, onlinesaleid },
        message: "aosSubmit gql api call error",
      });
      console.log("OfaZoidReactComponent aosSubmit service error", error);
      throw error?.response?.data;
    }
  }
}

export default {
  id: "ofa:feature-app-ofa-service",

  create: () => {
    return {
      "1.0.0": () => ({
        featureService: new FeatureAppOfaService(),
      }),
    };
  },
};

/**
 * Audi returns date in format YYYY-MM-DD
 * Format the date to MM/DD/YYYY
 * @param date
 */
export function formatDate(date: string) {
  if (date) {
    //Check if valid input
    if (/\d{4}-\d{2}-\d{2}/.test(date)) {
      const preFormatted = date.split("-");

      return `${preFormatted[1]}/${preFormatted[2]}/${preFormatted[0]}`;
    }

    throw new Error(`Invalid date format ${date}`);
  } else return "";
}
