// eslint-disable-next-line
export const vendorDataMapper: any = (data: any) => {
  return {
    correlationId: data.correlationId,
    brand: data.brand,
    contractCountry: data.contractCountry,
    preferredLanguage: data.preferredLanguage,

    primaryFirstName: data.primaryApplicant.firstName,
    primaryLastName: data.primaryApplicant.lastName,
    primaryEmail: data.primaryApplicant.emailAddress,
    primaryPhone: data.primaryApplicant.phoneNumber,

    primaryAddress1: data.primaryApplicant.residence.address.addressLine1,
    primaryAddress2: data.primaryApplicant.residence.address?.addressLine2,
    primaryCity: data.primaryApplicant.residence.address.city,
    primaryStateOrProvince: data.primaryApplicant.residence.address.StateOrProvince,
    primaryPostalCode: data.primaryApplicant.residence.address.zipOrPostalCode,
    primaryCountry: data.primaryApplicant.residence.address.country,

    dealerId: data.dealer.dealerId,
    marketingName: data.dealer?.marketingName,
    marketingAddress1: data.dealer?.marketingAddress1,
    marketingAddress2: data.dealer?.marketingAddress2,
    marketingCity: data.dealer?.marketingCity,
    marketingState: data.dealer?.marketingStateOrProvince,
    marketingZip: data.dealer?.marketingZipOrPostalCode,
    marketingPhone: data.dealer?.marketingPhone,

    vin: data.vehicle.vin,
    model: data.vehicle.model,
    Year: data.vehicle.year,
    trim: data.vehicle.trim,
    vehicleType: data.vehicle.vehicleType,
    modelCode: data.vehicle.modelCode,
    make: data.vehicle.make,
    inventory: data.vehicle.inventory,
    vehicleStockNumber: data.vehicle.vehicleStockNumber,

    mileage: data.financing.mileage || 0,
    financeType: data.financing.financeType,
    vehiclePrice: data.financing.vehiclePrice,
    term: data.financing.term,
    freightAndPDI: data.vehicle.vehicleType !== "Used" ? 0 : data.financing.freightAndPDI,
    paymentFrequency: data.financing.paymentFrequency,
    purchasePrice: data.financing.msrp,
    financedAmount: data.financing.financedAmount,
    paymentAmount: data.financing.paymentAmount,
    downPaymentAmount: data.financing.downPayment,
    finalAmount: data.financing.finalAmount,
    grossTradeIn: data.financing?.tradeInDetails?.grossTradeIn,
  };
};
