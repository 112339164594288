import { v4 as uuid } from "uuid";
import { logger } from "../../../logger";

const urlParams = new URLSearchParams(window.location.search);

const mapBrand = (inputBrand: string | undefined) => {
  if (inputBrand) {
    if (inputBrand.toLocaleLowerCase() === "volkswagen") {
      return "vw";
    }
    return inputBrand.toLocaleLowerCase();
  }
  return "audi";
};

const mapCountry = (inputCountry: string | undefined) => {
  if (inputCountry) {
    switch (inputCountry.toLocaleLowerCase()) {
      case "usa":
        return "us";
      case "can":
        return "ca";
      default:
        return inputCountry.toLocaleLowerCase();
    }
  }
  return "";
};

const getMappedvehicleData = ({ aossData, vehicleType }) => {
  return {
    inventory: 1,
    vehicleStockNumber: aossData?.vehicle?.vehicleStockNumber ?? "",
    cpo: 0,
    vin: aossData?.vehicle?.vin ?? "",
    make: aossData?.vehicle?.make ?? "",
    model: aossData?.vehicle?.model ?? "",
    modelCode: aossData?.vehicle?.modelCode ?? "",
    trim: aossData?.vehicle?.trim ?? "",
    year: aossData?.vehicle?.year ?? 2022, //tmp default to 2022
    vehicleType,
  };
};

const getMappedDealerData = ({ aossData }) => {
  return {
    dealerId: aossData?.dealer?.dealerId ?? "",
    marketingName: aossData?.dealer?.marketingName ?? "",
    marketingAddress1: aossData?.dealer?.marketingAddress1 ?? "",
    marketingAddress2: aossData?.dealer?.marketingAddress2 ?? "NA", // tmp default to NA since ofa validates this
    marketingCity: aossData?.dealer?.marketingCity ?? "",
    marketingStateOrProvince: aossData?.dealer?.marketingStateOrProvince ?? "",
    marketingZipOrPostalCode: aossData?.dealer?.marketingZipOrPostalCode ?? "",
    marketingPhone: getPhone(aossData?.dealer?.marketingPhone?.toString() ?? "6138418700"), //tmp default to testData phone
    //duplicate address since ofa needs marketingAddress
    marketingAddress: {
      marketingAddress1: aossData?.dealer?.marketingAddress1 ?? "",
      marketingAddress2: aossData?.dealer?.marketingAddress2 ?? "NA", // tmp default to NA since ofa validates this
      marketingCity: aossData?.dealer?.marketingCity ?? "",
      marketingStateOrProvince: aossData?.dealer?.marketingStateOrProvince ?? "",
      marketingZipOrPostalCode: aossData?.dealer?.marketingZipOrPostalCode ?? "",
    },
  };
};

const getMappedTradeInDetails = ({ aossData }) => {
  return {
    year: aossData?.tradeIn?.year ? parseInt(aossData?.tradeIn?.year, 10) : null,
    model: aossData?.tradeIn?.model ?? "",
    make: aossData?.tradeIn?.make ?? "",
    trim: aossData?.tradeIn?.trim ?? "",
    modelDescrtiption: aossData?.tradeIn?.model ?? "",
    grossTradeIn: aossData?.tradeIn?.grossTradeIn ?? null,
  };
}

const getMappedFinancingData = ({ aossData }) => {
  return {
    financeType: capitalizeFirst(aossData?.finance?.financeType?.toString() ?? "Loan"),
    downPayment: aossData?.finance?.downPayment ?? null,
    term: aossData?.finance?.termMonths ?? null,
    vehiclePrice: aossData?.finance?.vehiclePrice ?? null,
    financedAmount: aossData?.finance?.financedAmount ?? null,
    finalAmount: aossData?.finance?.finalAmount ?? null, // grossCapCost for Loan
    msrp: aossData?.finance?.purchasePrice ?? 54324,
    purchasePrice: aossData?.finance?.purchasePrice ?? 54324, //tmp
    mileage: aossData?.finance?.leaseMileage ?? null, // Optional
    paymentAmount: aossData?.finance?.paymentAmount ?? null,
    paymentFrequency: getPaymentFrequency(aossData?.finance?.paymentFrequency?.toString()),
    freightAndPDI: null, // aossData?.finance?.freightAndPDI This items is not available
    tradeInDetails: getMappedTradeInDetails({ aossData }),
  };
};

/**
 * Map aoss Data
 * @param {CreditAppPrefillResult} aossData
 * @returns
 */

export const aossDataMap = (aossData: CreditAppPrefillResult) => {
  const vehicleType = capitalizeFirst(aossData?.vehicle?.vehicleType?.toString() ?? "New");
  const mappedData = {
    correlationId: urlParams?.get("onlineSaleId") || uuid(),
    origin: window.origin,
    brand: mapBrand(aossData?.brand?.toString()),
    contractCountry: mapCountry(aossData?.contractCountry?.toString()),
    applicationType: capitalizeFirst(aossData?.creditApplicationType ?? ""),
    preferredLanguage: "en-us",

    vehicleType,
    inventory: 1,
    financeType: capitalizeFirst(aossData?.finance?.financeType?.toString() ?? "Loan"),

    requestId: `${aossData?.contractCountry}-${aossData?.brand}-${aossData?.correlationId}`, // check the validation and remove if not required
    id: `${aossData?.contractCountry}-${aossData?.brand}-${aossData?.vehicle?.vehicleStockNumber ?? aossData?.vehicle?.vin}`, // check the validation and remove if not required
    
    vehicle: getMappedvehicleData({ aossData, vehicleType }),
    dealer: getMappedDealerData({ aossData }),
    financing: getMappedFinancingData({ aossData }),
  };

  const functionName = "aossDataMap";

  logger.activity(functionName, {
    event: mappedData,
    message: "aossData has been mapped",
  });

  return mappedData;
};

export function getPaymentFrequency(frequency: string | undefined) {
  switch (frequency) {
    case "BI_WEEKLY":
      return "Bi-Weekly";
    case "MONTHLY":
      return "Monthly";
    case "WEEKLY":
      return "Weekly";
    default:
      return "";
  }
}

export function getPhone(phone: string) {
  if (phone.length === 10) {
    return phone;
  }

  // Get only 10 digits
  return phone.slice(-10);
}

export function getPhoneType(type: string) {
  switch (type) {
    case "LANDLINE_PRIVATE":
      return "home";
    case "LANDLINE_BUSINESS":
      return "work";
    case "FAX_GENERAL":
      return "work";
    case "MOBILE_GENERAL":
    default:
      return "mobile";
  }
}

function capitalizeFirst(text: string) {
  return text.charAt(0).toUpperCase() + text.slice(1).toLocaleLowerCase();
}
