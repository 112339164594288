import React from "react";
import FeatureAppIntegrator from "./FeatureAppIntegrator/FeatureAppIntegrator";
import { logger } from "../../logger";

function FeatureAppComplete() {
  console.log('SPLUNK TEST')
  logger.info("FeatureAppComplete", {
    event: "FeatureAppComplete",
    message: "event",
  });
  return (
    <div>
      <FeatureAppIntegrator />
    </div>
  );
}
export default FeatureAppComplete;
