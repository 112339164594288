import { validationAudiUSA } from "./audi_usa_schema_validation";

// eslint-disable-next-line
const validate: any = (data: any, brandCountry: any) => {
  if (brandCountry.isAudiUsa) {
    return validationAudiUSA(data);
  }
};

export default validate;
