import Joi from "joi";
import { getErrorCodes } from "../app-data-validation/internal_errorCodes";

const US_States = [
  "AL",
  "AK",
  "AS",
  "AZ",
  "AR",
  "CA",
  "CO",
  "CT",
  "DE",
  "DC",
  "FM",
  "FL",
  "GA",
  "GU",
  "HI",
  "ID",
  "IL",
  "IN",
  "IA",
  "KS",
  "KY",
  "LA",
  "ME",
  "MH",
  "MD",
  "MA",
  "MI",
  "MN",
  "MS",
  "MO",
  "MT",
  "NE",
  "NV",
  "NH",
  "NJ",
  "NM",
  "NY",
  "NC",
  "ND",
  "MP",
  "OH",
  "OK",
  "OR",
  "PW",
  "PA",
  "PR",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VT",
  "VI",
  "VA",
  "WA",
  "WV",
  "WI",
  "WY",
];

export const audi_usa_schema = Joi.object({
  correlationId: Joi.string().required(),
  brand: Joi.string().valid("vw", "audi", "volkswagen").insensitive().required(),
  contractCountry: Joi.string().valid("us", "ca", "usa", "can").insensitive().required(),
  preferredLanguage: Joi.string().valid("en-us", "en", "enus").insensitive().required(),

  // Primary applicant
  primaryFirstName: Joi.string().min(2).max(20).required(),
  primaryLastName: Joi.string().min(2).max(20).required(),
  primaryEmail: Joi.string()
    .email({ tlds: { allow: false } })
    .required(),
  primaryPhone: Joi.string().length(10).regex(/^\d+$/),

  primaryAddress1: Joi.string().min(2).max(40).required(),
  primaryAddress2: Joi.string().max(40).allow(null, "").optional(),
  primaryCity: Joi.string().min(2).max(30).required(),
  primaryStateOrProvince: Joi.string()
    .valid(...US_States)
    .required(),
  primaryPostalCode: [Joi.string().length(5).regex(/^\d+$/).required(), Joi.string().length(9).regex(/^\d+$/).required()],
  primaryCountry: Joi.string().valid("us", "ca").insensitive().required(),
  // Dealer
  dealerId: Joi.string().alphanum().length(6).required(),
  marketingName: Joi.string().min(2).max(50).required(),
  marketingAddress1: Joi.string().min(2).max(40).required(),
  marketingAddress2: Joi.string().max(40).optional(),
  marketingCity: Joi.string().min(2).max(30).required(),
  marketingState: Joi.string()
    .valid(...US_States)
    .required(),
  marketingZip: [Joi.string().length(5).regex(/^\d+$/).required(), Joi.string().length(9).regex(/^\d+$/).required()],
  marketingPhone: Joi.number().integer().min(1000000000).max(9999999999).required(),

  // Vehicle
  // vin: Joi.string().alphanum().length(17).regex(/^[a-zA-Z0-9]*$/).allow(null, '').optional(),
  model: Joi.string().min(2).max(40).required(),
  Year: Joi.number().min(2000).max(2100).required(),
  trim: Joi.string().min(2).max(50).required(),
  make: Joi.string().min(1).max(30).required(),
  // modelCode: Joi.string().min(1).max(20).allow(null, '').optional(),
  // inventory: Joi.number().valid(0, 1).allow(null).optional(),
  // vehicleStockNumber: Joi.string().min(2).max(20).allow(null, '').optional(),
  vehicleType: Joi.string().valid("New", "Used", "cpo", "demo").insensitive().required(),

  // Finance
  // mileage: Joi.number().min(0).max(999999).optional(),
  /*
    freightAndPDI: Joi.when('vehicleType', {
      is: Joi.string().valid('Used'),
      then: Joi.number().required(),
      otherwise: Joi.number().optional()
    }),
    */
  financeType: Joi.string().valid("Lease", "Loan", "Balloon").insensitive().required(),
  /*
    finalAmount: Joi.number().max(9999999999).when('financeType', {
      is: 'Loan',
      then: Joi.required(),
      otherwise: Joi.optional()
    }),
    */
  term: Joi.number().valid(12, 24, 30, 36, 39, 42, 48, 60, 72, 75).required(),
  paymentFrequency: Joi.string().valid("Weekly", "Bi-Weekly", "Monthly").required(),
  financedAmount: Joi.number().min(7000).required(),
  /*
    purchasePrice:Joi.when('financeType',{
      is:Joi.string().valid('Lease', 'Balloon'),
      then: Joi.number().max(9999999999).required(),
      otherwise: Joi.number().max(9999999999).optional(),
    }),
    financedAmount: Joi.when('financeType', {
      is: Joi.string().valid('Loan', 'Balloon'),
      then:Joi.number().max(9999999999).required(),
      otherwise: Joi.number().max(9999999999).optional()
    }),
    paymentAmount: Joi.number().max(9999999999).when('financeType', {
        is: 'Lease',
        then: Joi.required(),
        otherwise: Joi.optional()
    }),
    downPaymentAmount: Joi.number().max(9999999999).optional(),
    specialPrograms: Joi.string().length(3).optional(),
    vehiclePrice: Joi.when('vehicleType', {
      is:Joi.string().valid('New', 'Demo'),
      then:Joi.number().max(999999999).required(),
      otherwise: Joi.number().max(999999999).optional()
    }),
    grossTradeIn: Joi.number().max(9999999999).optional().allow(null, 0),
    */
});

export const validationOptions = {
  abortEarly: false, // abort after the last validation error
  allowUnknown: true, // allow unknown keys that will be ignored
  stripUnknown: true, // remove unknown keys from the validated data
};

export const validationAudiUSA: any = async (_vendorData: any) => {
  console.log("AUDI_USA_Validation_DATA", _vendorData);
  try {
    const response = await audi_usa_schema.validateAsync(_vendorData, validationOptions);
    return {
      isDataValid: true,
      responseValue: response,
    };
  } catch (err: any) {
    const { errorCodesList, showInternalError } = getErrorCodes(err.message);
    console.log("AUDI_USA_Validation_error", err.message);
    return {
      isDataValid: false,
      errorMessage: err.message,
      errorCode: 500,
      errorType: "data-error",
      errorDetail: errorCodesList,
      showInternalError,
    };
  }
};
