/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-var */
/* eslint-disable vars-on-top */
/* eslint-disable react/function-component-definition */
/* eslint-disable import/extensions */
/* eslint-disable no-unused-vars */
import React, { CSSProperties } from "react";
import ReactDOM from "react-dom";
// @ts-ignore
import * as zoid from "zoid/dist/zoid.frameworks.js";

import type { IAuthServiceV2 } from "@oneaudi/audi-auth-service";
import { FeatureAppOfaService as OfaService } from "../featureAppService/OfaServices";
import { defaultContainerTemplate } from "./zoidContainer";
import { buildCTAUrl } from "./common";
import { logger } from "../../../logger";

export type OfaApplicant = {
  firstName: string;
  lastName: string;
  dateOfBirth: string | undefined;
  identificationNumber: string;
  monthlyLivingExpenses: string;
  emailAddress: string;
  phoneNumber: string;
  phoneType: string;

  acknowledgeConsent: {
    consentPipeda: true;
    consentCasl: true;
  };

  residence: {
    address: {
      addressLine1: string;
      addressLine2: string;
      city: string;
      zipOrPostalCode: string;
      postalCode: null;
      StateOrProvince: string;
      country: string;
    };
    timeAtAddress?: {
      years: string;
      months: string;
    };
    residenceType: string;
  };

  employment?: {
    current: {
      employmentType: string;
      timeAtJob: {
        employmentType: string;
        years: string;
        months: string;
      };
      occupation: string;
      employerName: string;
    };
    previous: {
      employmentType: string;
      timeAtJob: {
        employmentType: string;
        years: string;
        months: string;
      };
      occupation: string;
      employerName: string;
    };
  };
  incomes?: {
    EmploymentIncome: {
      grossAmount: string;
      incomeFrequency: string;
      incomeType: string;
    };
    OtherIncome: {
      grossAmount: string;
      incomeFrequency: string;
      incomeType: string;
    };
  };
};

export type OfaData = {
  correlationId: string;
  origin: string;
  brand: string;
  contractCountry: string;
  applicationType: string;
  preferredLanguage: string;
  vehicleType: string;
  inventory: number;
  financeType: string;
  requestId: string;
  id: string;
  vehicle: {
    inventory: number;
    vehicleStockNumber: string;
    cpo: number;
    vin: string;
    make: string;
    model: string;
    modelCode: string;
    trim: string;
    year: number;
    vehicleType: string;
  };

  dealer: {
    dealerId: string;
    marketingName: string;
    marketingAddress1: string;
    marketingAddress2: string;
    marketingCity: string;
    marketingStateOrProvince: string;
    marketingZipOrPostalCode: string;
    marketingPhone: string;
  };

  financing: {
    financeType: string;
    downPayment: number | null;
    term: number | null;
    vehiclePrice: number | null;
    financedAmount: number | null;
    finalAmount: number | null; // grossCapCost for Loan
    purchasePrice: number | null;
    mileage?: number | null; // Optional
    paymentAmount: number | null;
    paymentFrequency: string;
    freightAndPDI: number | null;
    tradeInDetails: {
      year: number | null;
      model: string;
      make: string;
      trim: string;
      modelDescrtiption: string;
      grossTradeIn: number | null;
    };
  };

  primaryApplicant: OfaApplicant;
};

export type MappedData = {
  data: OfaData;
  contractCountry: string;
};

const OfaZoidComponent = zoid.create({
  tag: process.env.REACT_APP_FEATURE_APP_TAG,
  url: process.env.REACT_APP_FEATURE_APP_HOST,
  prerenderTemplate: null,
  autoResize: {
    height: true,
    width: true,
  },
  dimensions: {
    width: "100% !important",
    height: "100%",
  },
  containerTemplate: defaultContainerTemplate,

  props: {
    payload: {
      type: "object",
      required: true,
    },
    zoidCallBack: {
      type: "function",
      required: true,
    },
    zoidScrollTo: {
      type: "function",
      required: true,
    },
    zoidCTA: {
      type: "function",
      required: true,
    },
  },
});

const OfaZoidReactComponent = OfaZoidComponent.driver("react", {
  React,
  ReactDOM,
});

export type Origin = {
  isAudiUsa: boolean;
  isAudiCa: boolean;
  isFeatureApp: boolean;
  domain: string;
};

type OfaProps = {
  parms: object;
  profileData: OfaData;
  ofaService: OfaService;
  authService: IAuthServiceV2;
  onlineSaleId: string;
  origin: Origin;
};

//All of these actions occur in the hosting (parent) domain (i.e. audi.ca)
function Ofa({ parms, profileData, ofaService, authService, onlineSaleId, origin }: OfaProps) {
  console.warn(`OfaZoidReactComponent parms: ${JSON.stringify(parms)}`);
  console.warn(`OfaZoidReactComponent Profile data: ${JSON.stringify(profileData)}`);
  console.warn(`OfaZoidReactComponent onlineSaleId: ${onlineSaleId}`);
  //This is a function that executes on the parent
  //This provies a mechasim for bi-directional data
  //This can be used for session keep alive, redirects, setting storage, etc.
  const doSomething = (data: any) => {
    console.log("OfaZoidReactComponent callback data", data);
    sessionStorage.setItem("userActive", Date.now().toString());
  };

  const onAOSSubmitSuccess = async (dmsNumber = "") => {
    const token = await authService.getAccessToken();
    try {
      console.log("OfaZoidReactComponent onAOSSubmitSuccess-dmsNumber from OFA:", dmsNumber);
      await ofaService.aosSubmit(token, onlineSaleId, dmsNumber);
    } catch (error) {
      console.log("OfaZoidReactComponent onAOSSubmitSuccess-error:", error);
    }
  };

  /**
   * Returns an element's position relative to the document.
   * @param el
   * @returns {left: number, top: number}
   */
  function getOffset(el: HTMLElement) {
    const rect = el.getBoundingClientRect();
    return {
      left: rect.left + window.scrollX,
      top: rect.top + window.scrollY,
    };
  }

  const zoidCallBack = (data: any) => {
    if (data?.dmsNumber) {
      logger.activity("zoidCallBack-submitApp", { data });
      return onAOSSubmitSuccess(data?.dmsNumber);
    }
    logger.error("zoidCallBack-submitApp-error", { data });
    console.log("OfaZoidReactComponent ERROR FROM OFA", data);
  };

  const zoidCTA = () => {
    const url = buildCTAUrl();

    window.location.replace(url);
  };

  const zoidScrollTo = (top: number) => {
    const el = document.getElementById("zoid-ofa-container")!;
    const offSet = getOffset(el);
    const calculatedTop = offSet.top + top;
    window.scrollTo({
      left: window.scrollX,
      top: calculatedTop,
      behavior: "smooth",
    });
  };

  const zoidContainerStyle: CSSProperties = {
    display: "flex",
    flexDirection: "column",
  } as React.CSSProperties;


  return (
    <div id="zoid-ofa-container" style={zoidContainerStyle}>
      <OfaZoidReactComponent
        payload={profileData}
        callback={(data: any) => doSomething(data)}
        zoidScrollTo={zoidScrollTo}
        zoidCallBack={zoidCallBack}
        zoidCTA={zoidCTA}
        origin={origin}
      />
    </div>
  );
}

export default Ofa;
